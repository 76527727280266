<template>
    <div class="helpCenter-wrap">
        <van-nav-bar
            title="帮助中心"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="helpCenter-main-wrap">
            <van-tabs v-model:active="tabActive" :lazy-render="false" line-width="0" line-height="0" @change="handleScrollTop">
                <van-tab title="买币">
                    <van-image class="content-img" :src="store.state.publicConfig.buy_pic" />
                </van-tab>
                <van-tab title="卖币">
                    <van-image class="content-img" :src="store.state.publicConfig.sell_pic" />
                </van-tab>
                <van-tab title="充值">
                    <van-image class="content-img" :src="store.state.publicConfig.recharge_pic" />
                </van-tab>
                <van-tab title="提现">
                    <van-image class="content-img" :src="store.state.publicConfig.withdraw_pic" />
                </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
import { ref,computed,inject } from 'vue'
import { useStore } from "vuex";

export default {
    setup () {
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        const store = useStore();
        const windowScrollTop = inject('$windowScrollTop')
        const handleScrollTop = () => {
            document.getElementsByClassName('van-tabs__content')[0].scrollTo(0,0)
        }
        const tabActive = ref(0);
        return {
            onClickLeft,
            tabActive,
            windowScrollTop,
            handleScrollTop,
            store
        }
    }
}
</script>

<style lang="less" scoped>
.helpCenter-wrap {
    .helpCenter-main-wrap {
        height: calc(100vh - 46px);
        padding: 24px 16px;
        background: var(--mainBgColor);
        overflow: hidden;
        :deep(.van-tabs) {
            height: 100%;
            background: var(--boxBgColor);
            border-radius: 8px;
            overflow: hidden;
            .van-tabs__nav {
                background: var(--boxBgColor);
                border-radius: 8px;
            }
            .van-tab {
                color: #9C5E5C;
                .van-tab__text {
                    width: 62px;
                    height: 30px;
                    background: #FFFFFF;
                    line-height: 30px;
                    text-align: center;
                    border-radius: 23px;
                    font-weight: 500;
                    font-size: 16px;
                    color: var(--helepTabActiveTextColor);
                }
                &.van-tab--active {
                    .van-tab__text {
                        background: var(--btnBgColor);
                        color: #fff;
                    }
                }
            }
            .van-tabs__content {
                height: calc(100% - 44px);
                padding: 17px 27px;
                overflow: scroll;
            }
        }
    }
}
</style>