<template>
    <div class="aboutUs-wrap">
        <van-nav-bar
            title="关于我们"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="aboutUs-main-wrap">
            <div class="aboutUs-main-box" v-show="aboutContent" v-html="handleContent(aboutContent)"></div>
        </div>
    </div>
</template>

<script>
import { ref,onMounted } from 'vue'
import {getAbout} from '@/api/api'
import { handleContent } from '@/utils'

export default {
    setup () {
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        // 请求数据接口
        const handleGetAbout = () => {
            getAbout().then(res => {
                if(res.result == 200000) {
                    aboutContent.value = res.data.info
                }
            })
        }
        onMounted(() => {
            handleGetAbout()
        })
        // 存储内容
        const aboutContent = ref('')
        return {
            onClickLeft,
            aboutContent,
            handleContent
        }
    }
}
</script>

<style lang="less" scoped>
.aboutUs-wrap {
    .aboutUs-main-wrap {
        height: calc(100vh - 46px);
        padding: 24px 16px;
        background: var(--mainBgColor);
        .aboutUs-main-box {
            padding: 20px;
            background: var(--boxBgColor);
            border-radius: 8px;
            font-size: 15px;
            line-height: 180%;
            letter-spacing: 0.5px;
            color: var(--textCommonColor);
            text-indent: 2em;
            word-wrap: break-word;
            word-break: break-all;
        }
    }
}
</style>
