<template>
    <div class="feedBack-wrap">
        <van-nav-bar
            title="意见反馈"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="feedBack-main-wrap">
            <div class="feedBack-main-box">
                <van-field v-model="value" placeholder="标题" autocomplete="off" />
                <van-field
                    v-model="message"
                    rows="8"
                    autosize
                    type="textarea"
                    placeholder="请输入内容"
                />
            </div>
            <div class="comfirm-btn-box">
                <van-button block color="linear-gradient(180deg, #F9D6D1 0%, #D27C6D 45.83%)">确认</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'

export default {
    setup () {
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
    
        return {
            onClickLeft
        }
    }
}
</script>

<style lang="less" scoped>
.feedBack-wrap {
    .feedBack-main-wrap {
        height: calc(100vh - 54px);
        margin-top: 8px;
        padding: 24px 16px;
        background: #fff;
        .feedBack-main-box {
            .van-field {
                margin-bottom: 8px;
                background: #FBF1F0;
                border-radius: 8px;
            }
        }
        .comfirm-btn-box {
            margin-top: 16px;
            border-radius: 8px;
            .van-button {
                border-radius: 8px;
            }
        }
    }
}
</style>