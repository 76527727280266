<template>
    <div class="my-wrap">
        <van-nav-bar
            title="个人中心"
            :border="false"
            fixed
        />
        <div class="my-main-wrap">
            <div class="account-info-wrap">
                <div class="realname-link-box" v-if="memberInfo.is_name == 2 && !isShowDiv">
                    <van-image round :src="getAvatar(memberInfo.avatarNew)" />
                    <div class="realname-box">
                        <span class="name">{{memberInfo.nick_name}}</span>
                    </div>
                </div>
                <router-link to="/personalData" class="realname-link-box" v-if="memberInfo.is_name == 2 && isShowDiv">
                    <van-image round :src="getAvatar(memberInfo.avatarNew)" />
                    <div class="realname-box">
                        <span class="name">{{memberInfo.nick_name}}</span>
                        <span class="phone">{{memberInfo.username}}</span>
                    </div>
                </router-link>
                <router-link to="/certification" class="unrealname-box" v-if="memberInfo.is_name == 1 && isShowDiv && siteName !== 'agpay'">
                    <span class="label">请实名</span>
                    <van-icon :name="themeImgs.myUnCertificationIcon" />
                </router-link>
            </div>
            <div class="option-wrap" :style="`background:url(${themeImgs.myRadianBgImg})`">
                <div class="option-box">
                    <div class="option-item" @click="openCertificationUrl" v-if="isShowDiv && siteName !== 'agpay'">
                        <van-image :src="themeImgs.myCertificationIcon" />
                        <span class="label">{{memberInfo.is_name == 1?'请实名':memberInfo.is_name == 2?'已实名':'审核中'}}</span>
                    </div>
                    <router-link to="/helpCenter" class="option-item">
                        <van-image :src="themeImgs.myHelpIcon" />
                        <span class="label">帮助中心</span>
                    </router-link>
                    <router-link to="/aboutUs" class="option-item">
                        <van-image :src="themeImgs.myAboutIcon" />
                        <span class="label">关于我们</span>
                    </router-link>
                </div>
            </div>
            <van-cell-group class="wallet-wrap" :border="false">
                <van-cell class="wallet-address" title="钱包地址" center :value="memberInfo.wallet_address">
                    <template #icon>
                        <van-image :src="themeImgs.myIcon1" />
                    </template>
                    <template #right-icon>
                        <van-image :src="themeImgs.commonCopyIcon" @click="copyAddress(memberInfo.wallet_address)" />
                    </template>
                </van-cell>
            </van-cell-group>
            <van-cell-group class="wallet-wrap" :border="false">
<!--                <van-cell title="转账" center is-link to="/transfer">-->
<!--                    <template #icon>-->
<!--                        <van-image :src="themeImgs.myIcon2" />-->
<!--                    </template>-->
<!--                </van-cell>-->
                <van-cell v-if="isShowDiv" title="修改登录密码" center is-link to="/modifyLoginPassword">
                    <template #icon>
                        <van-image :src="themeImgs.myIcon3" />
                    </template>
                </van-cell>
                <van-cell title="修改支付密码" center is-link to="/modifyPaymentPassword">
                    <template #icon>
                        <van-image :src="themeImgs.myIcon4" />
                    </template>
                </van-cell>
            </van-cell-group>
            <van-cell-group class="wallet-wrap" :border="false">
                <van-cell title="资金明细" center is-link to="/fundRecord">
                    <template #icon>
                        <van-image :src="themeImgs.myIcon5" />
                    </template>
                </van-cell>
                <van-cell title="个人报表" center is-link to="/personalReport">
                    <template #icon>
                        <van-image :src="themeImgs.myIcon6" />
                    </template>
                </van-cell>
                <van-cell v-if="siteName !== 'qtpay'" title="代理佣金" center is-link to="/agencyCommission">
                    <template #icon>
                        <van-image :src="themeImgs.myIcon7" />
                    </template>
                </van-cell>

              <van-cell v-if="siteName === 'qtpay'" title="客服" center @click="setShowServicePopup(true)">
                <template #icon>
                  <van-image :src="themeImgs.myIcon8" />
                </template>
              </van-cell>
            </van-cell-group>
            <!-- <van-cell-group class="wallet-wrap">
                <van-cell title="登录设备管理" center is-link to="/deviceManagement">
                    <template #icon>
                        <van-image :src="require('../../assets/images/my/icon3.png')" />
                    </template>
                </van-cell>
                <van-cell title="意见反馈" center is-link to="/feedBack">
                    <template #icon>
                        <van-image :src="require('../../assets/images/my/icon4.png')" />
                    </template>
                </van-cell>
            </van-cell-group> -->
            <div v-if="isShowDiv" class="logout-btn-box">
                <van-button block @click="handleDoLogout">退出登录</van-button>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import { onMounted,ref,computed } from 'vue'
import useClipboard from 'vue-clipboard3'
import {Toast} from 'vant'
import Footer from "@/components/Footer"
import {getMemberInfo,logout} from '@/api/api'
import { useRouter } from "vue-router"
import { useStore } from 'vuex'
import wsConnection from "@/utils/websocket"
import { useGetAvatar} from "@hooks";

export default {
    components: {
        Footer
    },
    setup () {
      const siteName = ref(siteChannel)

      const $router = useRouter()
        const store = useStore()
        const { getAvatar } = useGetAvatar()
        // 获取会员个人信息
        const handleGetMemberInfo = () => {
            getMemberInfo().then(res => {
                if(res.result == 200000) {
                    memberInfo.value = res.data
                    store.dispatch('saveUserInfosActions', res.data)
                }
            })
        }
        onMounted(() => {
            handleGetMemberInfo()
            ifFromOtherPlat()
        })

        const isShowDiv = ref(true)
        const ifFromOtherPlat = () => {
            // 是否从综合iframe或者其他第三方那过来的, type 1是正常用户， 2是集成用户，2不显示
            if(window.parent.frames['agIframe'] || localStorage.getItem('isFromThird') || store.state.loginInfos.type === 2) {
                isShowDiv.value = false
            }
        }
        // 存储会员个人信息
        const memberInfo = ref({})
        // 跳转实名认证页面
        const openCertificationUrl = () => {
            if(memberInfo.value.is_name == 2) {
                return Toast.fail('已实名')
            } else if(memberInfo.value.is_name == 3) {
                return Toast.fail('审核中')
            } else {
                $router.push('/certification')
            }
        }
        // 复制钱包地址
        const { toClipboard } = useClipboard()
        const copyAddress = async (content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 退出登录
        const handleDoLogout = () => {
            logout().then(res => {
                if(res.result == 200000) {
                    Toast.success('退出成功')
                    localStorage.removeItem("loginInfos")
                    if(wsConnection.$ws) {
                        wsConnection.$ws.close()
                    }
                    // 针对综合的退出
                    if(window.parent.frames['agIframe']) {
                        window.parent.postMessage('退出登录', store.state.loginInfos.originUrl)
                    } else {
                        $router.push('/login');
                    }
                }
            })
        }

      const setShowServicePopup = (flag) => {
        store.commit('setShowServicePopup', flag)
      }

        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)

        return {
            memberInfo,
            openCertificationUrl,
            copyAddress,
            handleDoLogout,
            themeImgs,
            isShowDiv,
            getAvatar,
            siteName,
            setShowServicePopup
        }
    }
}
</script>

<style lang="less" scoped>
.my-wrap {
    .my-main-wrap {
        position: fixed;
        top: 46px;
        bottom: 50px;
        width: 100%;
        padding-top: 24px;
        background: var(--mainBgColor);
        overflow-y: auto;
        .account-info-wrap {
            display: flex;
            align-items: center;
            width: 351px;
            height: 106.5px;
            margin: 0 auto;
            padding-left: 24px;
            background: var(--btnBgColor);
            border-radius: 6px;

          .realname-link-box {
            display: flex;

            .van-image {
              width: 60px;
              height: 60px;
              margin-right: 12px;
            }
            .realname-box {
              display: flex;
              flex-direction: column;
              justify-content: center;
              span {
                color: #fff;
                font-weight: 500;
                &.name {
                  margin-bottom: 4px;
                  line-height: 25px;
                  font-size: 18px;
                  text-transform: capitalize;
                }
                &.phone {
                  font-size: 16px;
                  line-height: 22px;
                }
              }
            }

            .go-arrow {
              margin-left: 40px;
              align-self: center;
            }
          }

            .unrealname-box {
                display: flex;
                align-items: center;
                .label {
                    margin-right: 6px;
                    font-weight: 500;
                    font-size: 18px;
                    color: #fff;
                }
                :deep(.van-icon) {
                    width: 24px;
                    height: 24px;
                    margin-top: 2px;
                    .van-icon__image {
                        width: 24px;
                        height: 24px;
                    }
                }
            }
        }
        .option-wrap {
            margin-top: -20px;
            padding-top: 34px;
            height: 122px;
            background-size: 100% 100% !important;
            .option-box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 88px;
                .option-item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .van-image {
                        width: 32px;
                        height: 32px;
                        margin-bottom: 8px;
                    }
                    .label {
                        font-size: 16px;
                        letter-spacing: 0.02em;
                        color: var(--textCommonColor);
                    }
                }
            }
        }
        :deep(.wallet-wrap) {
            margin-top: 8px;
            .van-cell {
                background: var(--myCellBgColor);
            }
            .van-image {
                width: 24px;
                height: 24px;
            }
            .van-cell__title {
                margin-left: 24px;
                span {
                    font-size: 16px;
                }
            }
            .van-cell__value {
                flex: 0 0 59%;
                text-align: center;
                span {
                    color: var(--textColor);
                }
            }
        }
        .logout-btn-box {
            margin-top: 8px;
            padding: 20px 23px;
            background: var(--logoutBoxBgColor);
            border-radius: 8px;
            .van-button {
                border-radius: 8px;
            }
        }
    }
    @media only screen and (min-width: 750px) {
        .my-main-wrap {
            width: 375px;
        }
    }
}
</style>
